<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block pb-0 pb-md-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">Protection</div>
        </portal>
      </div>
    </div>

    <AccountSecurity />
  </v-container>
</template>

<script>
import AccountSecurity from '@/components/account/AccountSecurity'

export default {
  name: 'ProtectionCallLog',
  components: { AccountSecurity },
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
}
</script>