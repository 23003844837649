<template>
  <v-card
    class="create-card cardBg py-15 px-12 rounded-20"
    :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
    flat
    outlined
  >
    <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="4" lg="2">
        <TextDescription :description="description" />
      </v-col>

      <v-col cols="12" md="8" lg="10">
        <v-form ref="form" v-model="validateForm">
          <v-row no-gutters>
            <v-col class="pb-10" cols="12" sm="7" md="9" offset-sm="5" offset-md="3">
              <ToggleButton
                v-model="currentOptions"
                :buttons="btnOptions"
              />
            </v-col>
          </v-row>

          <v-scroll-y-transition hide-on-leave>
            <v-row no-gutters v-if="currentOptions === 2">
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Pin code</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="callLogPinCode"
                ></portal-target>
              </v-col>

              <v-col class="pb-10" cols="12" sm="7" md="9">
                <InputPassword
                  v-model="formData.pin"
                  :rules="rules"
                  class="field-container"
                >
                  <template #message="{ key, message }">
                    <portal to="callLogPinCode" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </InputPassword>
              </v-col>

<!--              <v-col-->
<!--                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"-->
<!--                cols="12" sm="5" md="3"-->
<!--              >-->
<!--                <span class="font-normal nameField&#45;&#45;text">Request code for reports older than</span>-->
<!--                <portal-target-->
<!--                  class="field-message font-normal error&#45;&#45;text text-sm-right"-->
<!--                  name="callLogDays"-->
<!--                ></portal-target>-->
<!--              </v-col>-->

<!--              <v-col class="pb-10" cols="12" sm="7" md="9">-->
<!--                <FormField-->
<!--                  v-model="formData.days"-->
<!--                  :rules="rules"-->
<!--                  type="number"-->
<!--                  class="field-container"-->
<!--                >-->
<!--                  <template #message="{ key, message }">-->
<!--                    <portal to="callLogDays" v-if="!!message">-->
<!--                      {{ message }}-->
<!--                    </portal>-->
<!--                  </template>-->
<!--                </FormField>-->
<!--              </v-col>-->

              <v-col cols="12" sm="7" md="9" offset="0" offset-sm="5" offset-md="3" class="mt-2 mt-sm-8">
                <ActionButton @click="submit" :loading="loading">Save</ActionButton>
              </v-col>
            </v-row>
          </v-scroll-y-transition>
        </v-form>
      </v-col>
    </v-row>

    <DialogSendConfirmationPinToEmail
      v-model="dialog"
      :formData="formData"
      :enable="currentOptions === 2"
      @updateSuccess="updateSuccess"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import { Types as authTypes } from '@/store/modules/auth'

import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import InputPassword from '@/components/inputs/InputPassword'
import ToggleButton from '@/components/buttons/ToggleButton'
import ActionButton from '@/components/buttons/ActionButton'
import DialogSendConfirmationPinToEmail from '@/components/dialog/DialogSendConfirmationPinToEmail'

import { sendPinCodeToEmail } from '@/api/security-routes'

export default {
  name: 'AccountSecurity',
  inheritAttrs: false,
  components: { TextDescription, FormField, InputPassword, ToggleButton, ActionButton, DialogSendConfirmationPinToEmail },
  data: () => ({
    loading: false,
    dialog: false,
    validateForm: true,
    description: {
      title: 'Call log security pin code',
      text: [],
    },
    currentOptions: 1,
    btnOptions: [
      { name: 'Disabled', id: 1 },
      { name: 'Enabled', id: 2 },
    ],
    formData: {
      pin: null,
      days: 1,
    },
    rules: [],
    detailsAccount: null,
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    ...mapGetters({
      callLogPinEnabled: authTypes.getters.CALL_LOG_PIN_ENABLED,
    }),
  },
  methods: {
    async submit() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true

        const { success, message } = await sendPinCodeToEmail()

        if (success) {
          this.dialog = true
          this.$notify({ type: 'success-bg', text: message })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    clearForm() {
      this.formData.pin = null
      this.rules = []
    },
    updateSuccess() {
      this.clearForm()
      this.dialog = false
    },
  },
  watch: {
    callLogPinEnabled: {
      immediate: true,
      handler(value) {
        this.dialog = false
        this.clearForm()

        this.currentOptions = !!value ? 2 : 1
      }
    },
    currentOptions: {
      handler(value) {
        if (value === 1 && this.callLogPinEnabled) {
          this.submit()
        }
      }
    },
    dialog: {
      handler(value) {
        if (!value) {
          if (this.callLogPinEnabled && this.currentOptions === 1) {
            this.currentOptions = 2
          }

          if (!this.callLogPinEnabled && this.currentOptions === 2) {
            this.currentOptions = 1
            this.clearForm()
          }
        }
      }
    }
  },
}
</script>