import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const checkCallLogDate = params => axios.get(`${getAccountId()}/calls/request-pin`, { params })
const checkCallLogPin = formData => axios.post(`${getAccountId()}/calls/validate-pin`, formData)

const sendPinCodeToEmail = () => axios.get(`${getAccountId()}/settings/pin`)
const savePinCodeForCallLog = formData => axios.patch(`${getAccountId()}/settings/pin`, formData)
const deletePinCodeForCallLog = params => axios.delete(`${getAccountId()}/settings/pin`, { params })

export {
  checkCallLogDate, checkCallLogPin, sendPinCodeToEmail, savePinCodeForCallLog, deletePinCodeForCallLog
}