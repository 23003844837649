<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="500px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters class="pb-4">
        <v-col cols="12" sm="3" class="pb-8 pr-11">
<!--          <div class="font-large-title text-uppercase dialogTitle&#45;&#45;text text-sm-right">-->
<!--            Send code-->
<!--          </div>-->
        </v-col>

        <v-col cols="12" sm="9" class="pb-8">
          <div class="font-normal nameField--text pt-1">
            Confirmation email has been sent to your email
          </div>
        </v-col>
      </v-row>

      <v-form class="pb-10" ref="form" v-model="validForm" @submit.prevent="submitForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10" cols="12" sm="3">
            <span class="font-normal nameField--text text-sm-right">Code</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="pinCodeFromEmail"
            ></portal-target>
          </v-col>

          <v-col class="pb-8 pb-sm-10" cols="12" sm="9">
            <InputPassword
              v-model="code"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="pinCodeFromEmail" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </InputPassword>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>

        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Send</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import InputPassword from '@/components/inputs/InputPassword'
import SwitchLg from '@/components/controls/SwitchLg'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { savePinCodeForCallLog, deletePinCodeForCallLog } from '@/api/security-routes'

import { mapMutations } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'DialogSendConfirmationPinToEmail',
  inheritAttrs: false,
  components: { InputPassword, SwitchLg, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    enable: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    loading: false,
    validForm: true,
    code: null,
    rules: []
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    ...mapMutations({
      updatePinEnable: authTypes.mutations.UPDATE_CALL_LOG_PIN_ENABLED,
    }),
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        if (this.enable) {
          this.savePinCodeForCallLog()
        } else {
          this.deletePinCodForCallLog()
        }
      }
    },
    async savePinCodeForCallLog() {
      this.loading = true

      const formData = Object.assign({}, this.formData, { code: this.code })

      const { success, payload, message } = await savePinCodeForCallLog(formData)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.updatePinEnable(true)
        this.$emit('updateSuccess')
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async deletePinCodForCallLog() {
      this.loading = true

      const { success, payload, message } = await deletePinCodeForCallLog({ code: this.code })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.updatePinEnable(false)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    closeDialog() {
      this.$emit('closeDialog')
      this.show = false
    },
  },
  watch: {
    show(value) {
      if (!value) {
        this.rules = []
        this.$refs.form.reset()
        this.loading = false
      }
    },
  },
}
</script>